<script>
import PrintSummary from '@/components/pages/restaurantAdmin/PrintSummary'

export default {
    components: { PrintSummary },
    props: {
        ccIdentifier: {
            type: String,
            default: null,
        },
    },
}
</script>

<template>
    <print-summary
        :order-identifier="ccIdentifier"
        :page="true"
    ></print-summary>
</template>
